/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_u3oPbUf3O",
    "aws_user_pools_web_client_id": "3pu5l9ir27qcccavbgovc0nloa",
    "endpoint":"https://sandbox.pagosauthapi.redebandigital.com",
    "oauth": {}
};


export default awsmobile;
