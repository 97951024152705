import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BinesSaveOrUpdateDto } from 'app/main/models/BinesSaveOrUpdateDto';
import { ListParametroDto } from 'app/main/models/ListParametroDto';
import { GeneralResponse } from 'app/main/models/GeneralResponse';
import { LocalStorageService } from 'app/main/service/local-storage.service';
import { HttpHeaders } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class BinesService  {

  public endPointPostGress:string = `${environment.apiAdminService}`;
  public comercio_id: number;
  public administrationService: string = `${environment.apiAdminService}`;
  

  /**
   * Constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient,
    private localStorageService:LocalStorageService) {
  }

  updateParametrosNotificaciones(request: { correoAlertas: string; correoNotificaciones: string; notificacionesDto: string; }) {
    this.comercio_id = this.localStorageService.getItem('comercioSelected');
    const endpoint = `${this.administrationService}/parametro/notificaciones/comercios/${this.comercio_id}`;

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    // Convertimos el objeto request en un string URL-encoded
    const body = new URLSearchParams();
    body.set('correoAlertas', request.correoAlertas);
    body.set('correoNotificaciones', request.correoNotificaciones);
    body.set('notificacionesDto', request.notificacionesDto);

    // Cambia `put` por `post`
    return this.http.post<GeneralResponse>(endpoint, body.toString(), { headers });
}



  /**
   * 
   */
    getParametrosNotificacionesByComercio() {

      this.comercio_id=this.localStorageService.getItem('comercioSelected')

      console.log("comercio:" , this.comercio_id)

      const endpoint = `${this.administrationService}/parametro/notificaciones/comercios/${this.comercio_id}`;

      return this.http.get<GeneralResponse>(endpoint);
    }

  
}
