import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CargaArchivoService {
  public endPointPostGress: string = `${environment.apiAdminService}`;
  public endPointStorage: string = `${environment.apiGenerateUrl}`;

  /**
   *
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) { }

  /**
   * 
   * @param filename 
   * @param comercioId 
   * @param convenio_name 
   * @param descripcion 
   * @param formatoId 
   * @returns 
   */
  getPresignedUrl(filename: string, comercioId: number, convenio: string, descripcion: string, formatoId: number) {
    let params = new HttpParams();
    params = params.append("nombreArchivo", filename);
    params = params.append("comercioId", comercioId);
    params = params.append("convenio", convenio);
    params = params.append("descripcion", descripcion);
    params = params.append("formatoId", formatoId);

    return this.http.get(`${this.endPointStorage}/presignedUrl`, {params:params}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  getComercio(categoriaId: number) {
    return this.http.get(`${this.endPointPostGress}/comercios/${categoriaId}/comercios_por_categoria`).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * 
   * @param comercioId 
   * @returns 
   */
  getConvenio(comercioId: number) {
    return this.http.get(`${this.endPointPostGress}/convenios/${comercioId}`).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * Servicio que obtiene convenios por un id comercio
   * @param comercioId 
   * @returns 
   */
  getConveniosPorComercio(comercioId:number) {
    return this.http.get(`${this.endPointPostGress}/convenios/${comercioId}/convenios_por_comercio`).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  /**
   * 
   * @param convenio_id
   * @returns 
   */
  getFormatobyId(convenio_id: number): Observable<any> {
    return this.http.get(`${this.endPointPostGress}/convenios/formatos/convenio/${convenio_id}`);
  }
  /**
   * 
   * @param
   * @returns
   */
  getFormato(): Observable<any>{
    return this.http.get(`${this.endPointPostGress}/formatos/listar`);
  }

  /**
  * Catch errors on call WS
  * @param error
  */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }


}