import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Transaccion } from 'app/main/models/transaccion';
import { SweetAlertService } from 'app/main/service/sweet-alert.service';
import { HistorialTransaccionesService } from '../historial-transacciones.service';

@Component({
  selector: 'app-detalle-transaccion',
  templateUrl: './detalle-transaccion.component.html',
  styleUrls: ['./detalle-transaccion.component.scss']
})
export class DetalleTransaccionComponent implements OnInit {

  public contentHeader: object;
  public formTransaccion:FormGroup;
  public cargandoTransaccion = false;
  public transaccion:Transaccion;
  public archivoId:string;

  constructor(private formBuilder:FormBuilder, private router:Router, private activatedRoute:ActivatedRoute,
              private alert:SweetAlertService, private historialTransaccionesService: HistorialTransaccionesService) { }

  ngOnInit(): void {
    console.log("Tx: ",  this.activatedRoute)
    this.contentHeader = {
      headerTitle: 'Ver Transacción',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Historial de Transacciones',
            isLink: true,
            link: '/historial-transacciones'
          },
          {
            name: 'Ver',
            isLink: false,
            link: '/'
          }
        ]
      }
    };
    this.formTransaccion = this.formBuilder.group({
      archivoId: [{value:'', disabled:true}],
      idTransaccion: [{value:'', disabled:true}],
      comercioId: [{value:'', disabled:true}],
      nombreComercio: [{value:'', disabled:true}],
      convenioId: [{value:'', disabled:true}],
      nombreConvenio: [{value:'', disabled:true}],
      token: [{value:'', disabled:true}],
      ultimosTarjeta: [{value:'', disabled:true}],
      naturalezaTransaccion: [{value:'', disabled:true}],
      valor: [{value:'', disabled:true}],
      valorIva: [{value:'', disabled:true}],
      valorBaseIva: [{value:'', disabled:true}],
      numeroCoutas: [{value: '', disabled: true}],
      tipoTarjeta: [{value:'', disabled:true}],
      tipoFranquicia: [{value:'', disabled:true}],
      codRespuesta: [{value:'', disabled:true}],
      descRespuesta: [{value:'', disabled:true}],
      numeroComprobante: [{value:'', disabled:true}],
      codigoAutorizacion: [{value:'', disabled:true}],
      estado: [{value:'', disabled:true}],
      fechaTransaccion: [{value:'', disabled:true}],
      numeroControl: [{value:'', disabled:true}],
      terminalId: [{value:'', disabled:true}],
      tarjetaEnmascarada: [{value:'', disabled:true}],
    });


    this.cargandoTransaccion = true;
    this.activatedRoute.params.subscribe(params => {
      let archivoId = params['archivoId'];
      this.archivoId = archivoId;
      let idTransaccion = params['idTransaccion'];
      this.historialTransaccionesService.getTransaccion(archivoId, idTransaccion).subscribe(data => {
        if(data != null){
          this.transaccion = data['data'] as Transaccion;
          this.cargarTransaccion();
          this.cargandoTransaccion = false;
        }else{
          this.cargandoTransaccion = false;
          this.alert.alertNotification('error', 'La transacción no existe!');
          this.linkTransacciones();
        }
      },err => {
        console.log(err);
        this.cargandoTransaccion = false;
        this.alert.alertNotification('error', 'Error al obtener la transacción, intentelo nuevamente!');
        this.linkTransacciones();
      })
    })
  }

  cargarTransaccion(){
    this.formTransaccion.setValue({
  
      archivoId: this.transaccion.archivoId,
      idTransaccion: this.transaccion.idTransaccion,
      comercioId: this.transaccion.comercioId,
      nombreComercio: this.transaccion.nombreComercio,
      convenioId: this.transaccion.convenioId,
      nombreConvenio: this.transaccion.nombreConvenio,
      token: this.transaccion.token,
      ultimosTarjeta: this.transaccion.ultimosTarjeta,
      naturalezaTransaccion: this.transaccion.naturalezaTransaccion,
      valor: this.transaccion.valor,
      valorIva: this.transaccion.valorIva,
      valorBaseIva: this.transaccion.valorBaseIva,
      numeroCoutas: this.transaccion.numeroCoutas,
      tipoTarjeta: this.transaccion.tipoTarjeta,
      tipoFranquicia: this.transaccion.tipoFranquicia,
      codRespuesta: this.transaccion.codRespuesta,
      descRespuesta: this.transaccion.descRespuesta,
      numeroComprobante: this.transaccion.numeroComprobante,
      codigoAutorizacion: this.transaccion.codigoAutorizacion,
      estado: this.transaccion.estado,
      fechaTransaccion: this.transaccion.fechaTransaccion,
      numeroControl: this.transaccion.numeroControl,
      terminalId: this.transaccion.terminalId,
      tarjetaEnmascarada: this.transaccion.tarjetaEnmascarada!=null?this.transaccion.tarjetaEnmascarada:"",
    })
  }

  linkTransacciones(){
    if(this.archivoId){
       console.log("ArchivoId que viaja a la pantalla de transacciones: ", this.archivoId);
       this.router.navigate(['/historial-transacciones/'+this.archivoId]);
       if(localStorage.getItem("consultaTxs") == "true"){
        this.router.navigate(['/historial-transacciones/']);
       }

    }
  }
}