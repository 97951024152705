import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EstadisticaService {

  private processEndPoint: string = `${environment.apiProcessService}`;
  private pathByComercioIdEstadistica = `${this.processEndPoint}/estadisticas/byComercioIdEstadistica`;

  constructor(private http:HttpClient) { }

  /**
   * Servicio para obtener las estadisticas de comercios
   * @param comercioId comercioId
   * @param fechaInicio FechaInicio para el filtro
   * @param fechaFin FechaFin para el filtro
   * @returns 
   */
  getEstadisticas(comercioId:number, fechaInicio:string, fechaFin:string){
    let params = new HttpParams();

    params = params.append('comercioId', comercioId);
    params = params.append('fechaInicial', fechaInicio);
    params = params.append('fechaFinal', fechaFin);
    
    return this.http.get(this.pathByComercioIdEstadistica, {params:params}).pipe(
      tap(response => {
        if(response){
        }
      }),
      catchError(this.handleError)
    )
  }

  formatDate(num: number): string {
    if (num < 10)
      return '0' + num;
    return num + '';
  }

   /**
  * Catch errors on call WS
  * @param error
  */
   private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ocurrió un error:', error.error.message);
    }
    return throwError(error);
  }
}