<app-content-header [contentHeader]="contentHeader"></app-content-header>
<div class="card">
  <div class="row d-flex justify-content-center py-3" *ngIf="cargandoTransaccion">
    <img src="assets/images/icons/loading.gif"/>
  </div>
  <div class="modal-content p-0" *ngIf="!cargandoTransaccion">
    <div class="card-body">
      <form class="form form-horizontal" [formGroup]="formTransaccion">
        <div class="row">
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Archivo Id</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="archivoId" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Id Transacción</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="idTransaccion" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Estado</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="estado" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Fecha Transacción</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="fechaTransaccion" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Código Respuesta</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="codRespuesta" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Mensaje Respuesta</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="descRespuesta" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Valor</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="valor" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Valor Iva</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="valorIva" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Valor Base Iva</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="valorBaseIva" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Número Comprobante</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="numeroComprobante" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Código Autorización</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="codigoAutorizacion" class="form-control"/>
              </div>
            </div>
          </div>
          <!-- <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Id Comercio</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="comercioId" class="form-control" disabled/>
              </div>
            </div>
          </div> -->
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Comercio</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="nombreComercio" class="form-control" disabled/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Convenio</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="nombreConvenio" class="form-control" disabled/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Número de Control</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="numeroControl" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Token</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="token" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Tarjeta Enmascarada</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="tarjetaEnmascarada" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Número Cuotas</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="numeroCoutas" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Tipo Franquicia</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="tipoFranquicia" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
              <div class="col-sm-2 col-form-label">
                <label for="first-name"><strong>Id Terminal</strong></label>
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="terminalId" class="form-control"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group row">
                <div class="col-md-2"></div>
                <div class="col-sm-12 col-12 col-md-10">
                  <button class="btn btn-primary" (click)="linkTransacciones()">Volver</button>
                </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>