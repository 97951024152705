<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <!-- <div class="bookmark-wrapper d-flex align-items-center"> -->
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
  </div>
  <!--/ Menu Toggler -->

  <!-- Toggle skin -->
  <ul class="nav navbar-nav align-items-center">
    <li class="nav-item d-none d-lg-block">
      <img class="brand-text3" src="../../../../assets/images/logo/redeban.png" height="40" />
    </li>
  </ul>
  <!--/ Toggle skin -->
  <!-- </div> -->

  <ul class="nav navbar-nav align-items-center ml-auto">
    
    <!-- Notification -->
    <app-navbar-notification></app-navbar-notification>
    <!-- Language selection -->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="fa-beaker"></i>
        <span><img src="../../../../assets/images/portrait/small/icons8-tienda-100.png" 
          height="23" width="25" /></span>
        <span class="selected-language"> {{ comercioOne.name }}
        </span>
        
        </a>
      <div ngbDropdownMenu aria-labelledby="dropdown-flag" class="dropdown-menu dropdown-menu-right">
        <a *ngFor="let comercio of comercios" [value]="comercio.id" ngbDropdownItem (click)="setComercio(comercio)">
          {{comercio.name}}
        </a>
      </div>
    </li>
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <!-- <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->
    <!--/ Toggle skin -->

 

    
    <!-- ? Language selection | Uncomment if needed-->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->
    <!--  -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-notification">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none">
          <!-- <span class="user-name font-weight-bolder">{{username}}</span> -->
         <!--  <span
            class="user-status">{{nombreComercio}}</span> -->
        </div>
        <span class="avatar"><img class="round" src="../../../../assets/images/portrait/small/noname.png" alt="avatar"
            height="40" width="40" /><span class="avatar-status-online"></span></span>
      </a>

      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item mt-0 waves-effect">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0 me-2">
              <div class="avatar avatar-online">
                <img src="../../../../assets/images/portrait/small/noname.png" alt="" height="30" width="30" class="rounded-circle">
              </div>
            
            </div>
            <div class="flex-grow-1">
              <h6>  {{username}}</h6>
              <!-- <small class="text-muted">Admin</small> -->
            </div>
          </div>
        </a>
        <li>
          <div class="dropdown-divider"></div>
        </li>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Salir</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>