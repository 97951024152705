import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';

import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthGuard, HttpInterceptorInterceptor } from 'app/auth/helpers';
import { HistorialCargaService } from './historial-carga/historial-carga.service';
import { HistorialTransaccionesService } from './historial-transacciones/historial-transacciones.service';

import { CoreCommonModule } from '@core/common.module';
import { CargaArchivosComponent } from './carga-archivos/carga-archivos.component';
import { HistorialCargaComponent } from './historial-carga/historial-carga.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { FileUploadModule } from 'ng2-file-upload';
import { HistorialTransaccionesComponent } from './historial-transacciones/historial-transacciones.component';
import { ListaNotificacionesComponent } from './lista-notificaciones/lista-notificaciones.component';
import { DetalleCargaComponent } from './historial-carga/detalle-carga/detalle-carga.component';
import { DetalleTransaccionComponent } from './historial-transacciones/detalle-transaccion/detalle-transaccion.component';
import { EstadisticaComponent } from './estadistica/estadistica.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { listarNotificaciones} from './parametrización/notificaciones/notificaciones.component';

const routes = [
  {
    path: 'notificaciones',
    component: listarNotificaciones,
    data: { animation: 'listarNotificaciones' },
    canActivate: [AuthGuard]
  },
  {
    path: 'estadisticas',
    component: EstadisticaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'carga',
    component: CargaArchivosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'historial-carga',
    component: HistorialCargaComponent,
    data: { animation: 'HistorialCargaComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'historial-carga/:comercioId/:idSolicitud/detalle',
    component: DetalleCargaComponent,
    data: { animation: 'DetalleCargaComponent' },
    canActivate: [AuthGuard]
  },
  {

    path: 'historial-transacciones',
    component: HistorialTransaccionesComponent,
    data: { animation: 'HistorialTransaccionesComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'historial-transacciones/:filename', 
    component: HistorialTransaccionesComponent,
    data: { animation: 'HistorialTransaccionesComponent' },
    canActivate: [AuthGuard]
  },
  {
    path: 'historial-transacciones/:archivoId/:idTransaccion/detalle', 
    component: DetalleTransaccionComponent,
    data: { animation: 'HistorialTransaccionesComponent' },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [listarNotificaciones,CargaArchivosComponent, HistorialCargaComponent, HistorialTransaccionesComponent, ListaNotificacionesComponent, DetalleCargaComponent, DetalleTransaccionComponent, EstadisticaComponent],
  imports: [
    BrowserModule,
    FileUploadModule,
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    NgbModule,
    NgSelectModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgApexchartsModule,
    NgxDatatableModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorInterceptor, multi: true},
    HistorialCargaService,
    HistorialTransaccionesService
  ],
  exports: [HistorialCargaComponent,HistorialTransaccionesComponent]
})
export class DashboardModule {}
