import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { NotificationsService } from 'app/main/dashboard/lista-notificaciones/notifications.service';

@Component({
  selector: 'app-lista-notificaciones',
  templateUrl: './lista-notificaciones.component.html',
  styleUrls: ['./lista-notificaciones.component.scss']
})
export class ListaNotificacionesComponent implements OnInit {
  //table proceso - primary key, index
  public comercio_id: string = '';
  public lastHashKey = null;
  public lastRangeKey = null;
  public lastRangeIndexKey =  null;
  
  //message to show
  public message_exitoso: string = 'Archivos Enviados con exito';
  public message_rechazado: string = 'Error al enviar los archivos';
  public message_anulado: string = 'Los archivos presentan inconvenientes';
  public message_proceso: string = 'La carga de los archivos estan en proceso...';
  
  public allRowsRead = false;
  public rows;
  public onInitloaded = false;
  public showRightPanel = false;
  public currentRow;

  constructor(private authService: AuthenticationService, private notificationsService: NotificationsService) { }

  ngOnInit(): void {
    let attributes = this.authService.getPayload();
    this.comercio_id = '';
    if (attributes['custom:comercio_id']) {
        this.comercio_id = attributes['custom:comercio_id'];
    }
    this.readNextRows(true);    
  }

  public readNextRows(firstTime = false) {
      if (this.allRowsRead) return ;
      this.notificationsService.readNextProcesos(this.comercio_id, 5, this.lastHashKey, this.lastRangeKey, this.lastRangeIndexKey).subscribe(response => {
        let next_rows = response['processes'];

        if (!response['pagination']) {
          this.allRowsRead = true;
        } else {
          this.lastHashKey = response['pagination']['startKeyComercioId'];
          this.lastRangeKey = response['pagination']['startKeyArchivoId'];
          this.lastRangeIndexKey = response['pagination']['startKeyFechaProceso'];
        }

        if (!this.rows)
          this.rows = response['processes'];
        else 
          this.rows = [...this.rows, ...next_rows]
        this.onInitloaded = true;

        if (this.rows.length > 0) {
            this.show(this.rows[0], 1);
        }
    
      });
  }

  public show(row, change) {
    this.showRightPanel = true;
    this.currentRow = row;
  }

}
