import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, tap, catchError } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service';
import { AuthService } from 'app/main/pages/authentication/authentication.service';
import { Auth } from 'aws-amplify';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  
  constructor(private _router: Router, private _authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)  {
    const user = this._authService.userValue;
    if (user) {
        console.log(user)
        if(user.retailers==null)
          this._router.navigate(['/pages/authentication/login-v1'], { queryParams: { returnUrl: state.url } });
        // logged in so return true
        return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/pages/authentication/login-v1'], { queryParams: { returnUrl: state.url } });
    return false;
}
}
