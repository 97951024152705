<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- File Uploader -->
    <section id="file-upload">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3">
              <h4>Adjuntar archivos</h4>
              <fieldset class="form-group">
                <label for="file-upload-single">Archivo:</label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" accept=".txt,.csv,.Txt,.TXT,.tXt,.txT,.tXT" ng2FileSelect [uploader]="uploader"
                    id="file-upload-single" (keyup)="fileInputSelected($event)" (click)="fileInputSelected($event)" />
                  <input type="text" hidden pattern="{{patternFile}}" [(ngModel)]="fileNameText" #fileInput="ngModel" />
                  <label class="custom-file-label">Selecciona un archivo</label>
                </div>
              </fieldset>
              <ngb-alert [type]="'warning'" [dismissible]="false" *ngIf="uploader.queue.length > 0 && fileInput.valid">
                <div class="alert-body">
                  No será posible cargar nuevamente el archivo: "<strong>{{fileNameText}}</strong>" en esta solicitud.
                </div>
              </ngb-alert>
            </div>

            <div class="col-lg-9 overflow-auto">
              <div class="d-flex justify-content-between">
                <h4>Detalle de archivo</h4>
              </div>
              <label for="file-upload-single"></label>

              <table class="table">
                <thead>
                  <tr>
                    <th width="50%">Nombre</th>
                    <th>Tamaño</th>
                    <th>Progreso</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody [ngClass]="{'bg-danger text-white': !fileInput.valid}">
                  <tr *ngFor="let item of uploader.queue">
                    <td>
                      <strong>{{ item?.file?.name }}</strong>
                    </td>
                    <td *ngIf="uploader.options.isHTML5" nowrap>
                      {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                    </td>
                    <td *ngIf="uploader.options.isHTML5">
                      <ngb-progressbar type="primary" [value]="item.progress"></ngb-progressbar>
                    </td>
                    <td class="text-center">
                      <span *ngIf="item.isSuccess"><i data-feather="check"></i></span>
                      <span *ngIf="item.isCancel"><i data-feather="slash"></i></span>
                      <span *ngIf="item.isError"><i data-feather="x-circle"></i></span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="form-group row mt-5">
                <label for="colFormLabel" class="col-sm-2 col-form-label">Descripción: </label>
                <div class="col-sm-8">
                  <input type="text" [(ngModel)]="descripcion" #description="ngModel" required class="form-control"
                    maxlength="100" id="colFormLabel" placeholder="Agregue una descripcion"/>
                    <!-- [ngClass]="{'is-invalid': (!description.valid && fileInput.valid && fileNameText.length > 0)}"  -->
                    
                </div>
              </div>


              <div class="form-group row mt-1">
                <label for="selectDefault" class="col-sm-2 col-form-label">Convenio: </label>
                <div class="col-sm-8">
                  <select class="form-control" id="selectDefault" [(ngModel)]="convenioSelected" name="selectConvenio"
                    #selectConvenio="ngModel"
                    [ngClass]="{'is-invalid': ((convenioSelected === -1 || selectConvenio.value === '-1') && fileInput.valid && fileNameText.length > 0)}">
                    <option *ngFor="let c of convenio" [value]="c.id">
                      {{c.nombre}}
                    </option>
                  </select>
                </div>
              </div>

             <!--  <div class="form-group row mt-1">
                <label for="selectDefault" class="col-sm-2 col-form-label">Formato: </label>
                <div class="col-sm-6">
                  <select class="form-control" id="selectDefault" [(ngModel)]="formatoSelected" name="selectFormato"
                    #selectFormato="ngModel" 
                    [ngClass]="{'is-invalid': ((formatoSelected === -1 || selectFormato.value === '-1') && convenioSelected !== -1 && selectConvenio.valid && description.valid && fileInput.valid && fileNameText.length > 0)}">
                    <option *ngFor="let f of formato" [value]="f.id">
                      {{f.nombre}}
                    </option>
                  </select>
                </div>
              </div> -->

              <div
                class="flex-column text-center bg-danger bg-darken-2 colors-container rounded text-white d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow">
                <div *ngIf="mensajeError?.length>0">
                  {{mensajeError}}
                </div>
                <div *ngIf="fileInput.errors?.pattern">
                  Solo se permiten archivos .txt, .csv con nombre alfanumérico sin caracteres especiales
                </div>
                <div *ngIf="isFileInputTouched && fileNameText.length === 0">
                  Debe seleccionar un archivo
                </div>
                <!-- <div *ngIf="!description.valid && (fileInput.valid && fileNameText.length > 0)">
                  Debe ingresar una descripción del archivo (max. 100 caracteres)
                </div>*/ -->
                <div
                  *ngIf="(selectConvenio.errors?.required || convenioSelected === -1 || selectConvenio.value === '-1') && (fileInput.valid && fileNameText.length > 0)">
                  Debe seleccionar el convenio
                </div>
                <!-- <div
                  *ngIf="(formatoSelected === -1 || selectFormato.value === '-1') && convenioSelected !== -1 && selectConvenio.valid && description.valid && (fileInput.valid && fileNameText.length > 0)">
                  Debe seleccionar el formato
              </div> -->
              </div>

              <div>
                <!--
                    <ngb-progressbar class="mb-1" type="primary" 
                    [value]="uploader.progress" *ngIf= "uploader.getNotUploadedItems().length > 0 || archivoCargado"></ngb-progressbar>
                -->
                <button *ngIf="!submitArchive" type="button" class="btn mr-1 btn-primary mb-1 mb-sm-0"
                  (click)="uploadFile()"
                  [disabled]="!uploader.getNotUploadedItems().length || convenioSelected === -1 || selectConvenio.value === '-1' || !fileInput.valid"
                  rippleEffect>
                  <p class="mt-1">Enviar</p>
                </button>
                <button *ngIf="submitArchive" type="button" class="btn mr-1 btn-primary mb-1 mb-sm-0" disabled
                  rippleEffect>
                  <p class="mt-1">Enviando</p>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <!--/ File Uploader -->
  </div>
</div>

<!-- Modal -->
<div class="modal-dialog modal-xl d-inline-block">
  <ng-template #modalSuccess let-modal>
    <div class="modal-header">
      <h5 class="text-primary modal-title pl-3 pr-3" id="myModalLabel160"><strong>Envio exitoso</strong></h5>
      <button type="button" class="close" (click)="initialize()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-justify" tabindex="0" ngbAutofocus>
      <h5 class="text-dark mb-2 pl-3 pr-3">Se esta procesando su transacción, puede verificar su estado en el historial
        de cargas.
        Pronto recibira una notificacion como respuesta
      </h5>
      <p></p>
      <h5 class="text-dark pl-5 pr-5">Resumen de su cargue:</h5>
      <p></p>
      <h5 class="text-dark pl-5 pr-5">Id solicitud: {{idSolicitud}}</h5>
      <h5 class="text-dark pl-5 pr-5">Convenio: {{convenioSelectedName}}</h5>
    </div>

    <div class="modal-footer">

      <a routerLink="/historial-carga">
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
          Ver historial de cargas
        </button>
      </a>

      <button type="button" class="btn btn-primary" (click)="initialize();" rippleEffect>
        Cargar otro archivo
      </button>

    </div>
  </ng-template>
</div>
<!-- / Modal -->