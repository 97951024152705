import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public processEndPoint:string = `${environment.apiProcessService}/processes`;
  public apiData = [];
  
  /**
   *
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {
  }

  readNextProcesos(comercio_id:string, cantidadRegistros:number, lastHashKey, lastRangeKey, lastRangeIndexKey) {
    if (lastHashKey) {
      return this.http.get(`${this.processEndPoint}/byComercioId?comercioId=${comercio_id}&maxResultSize=${cantidadRegistros}&startKeyComercioId=${lastHashKey}&startKeyArchivoId=${lastRangeKey}&startKeyFechaProceso=${lastRangeIndexKey}`);
    }
    return this.http.get(`${this.processEndPoint}/byComercioId?comercioId=${comercio_id}&maxResultSize=${cantidadRegistros}`);
  }
  
}
