<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- Historial List -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <section class="">
            <div class="card">
                <!-- Historial List Header -->
                <div class="ml-1 mr-1 mt-1 mb-1">
                    <div class="row">
                        <div class="col-12 mb-1">
                            <h5>Filtro de búsqueda</h5>
                        </div>
                        <div class="col-md-5 col-sm-12">
                            <div>
                                <div class="w-50">
                                    <select class="form-control" id="selectDefault"
                                    (change)="restarDiasALaFecha()" [(ngModel)]="timeSelected"
                                    [ngClass]="">
                                        <option *ngFor="let c of selectTime" [value]="c.value">
                                        {{c.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 col-12 d-flex align-items-right">
                            <input name="datepicker" class="hidden form-control" ngbDatepicker
                                #rangeSelectionDP="ngbDatepicker" [autoClose]="'outside'"
                                (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t"
                                outsideDays="hidden" [startDate]="fromDate!" [value]="formatter.format(today)"
                                tabindex="-1">

                            <ng-template #t let-date let-focused="focused">
                                <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                                    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                                    (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                </span>
                            </ng-template>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-5 mb-1">
                                    <div class="input-group">
                                        <input #dpFromDate placeholder="Fecha Inicio" name="dpFromDate"
                                            class="form-control" [value]="formatter.format(fromDate)"
                                            (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                            autocomplete="off">

                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary feather icon-calendar"
                                                (click)="rangeSelectionDP.toggle()" type="button" rippleEffect></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-5">
                                    <div class="input-group">
                                        <input #dpToDate class="form-control" placeholder="Fecha Final" name="dpToDate"
                                            [value]="formatter.format(toDate)"
                                            (input)="toDate = validateInput(toDate, dpToDate.value)" autocomplete="off">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary feather icon-calendar"
                                                (click)="rangeSelectionDP.toggle()" type="button" rippleEffect></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-2 mb-0 mb-sm-0 mt-1 mt-md-0">
                                    <button class="btn btn-primary mb-1" (click)="filterDate()"
                                        [disabled]="disableBtnFecha" rippleEffect>
                                        <strong>Filtrar</strong>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="card bg-secondary text-white" *ngIf="!showEstadistica">
                <div class="card-body">
                  <h4 class="card-title text-white">Selecciona un Rango de Fechas</h4>
                  <p class="card-text">Elige un período de tiempo para visualizar las estadísticas.</p>
                </div>
            </div> -->
              
            <div class="row match-height ng-star-inserted">
                <div class="col-md-4">
                    <div class="card card-congratulation-medal">
                        <div class="card-body">
                            <h4 style="font-weight: bold;">Procesos / Archivos</h4>
                            <p class="text-secondary">Total Archivos Cargados</p>
                            <h1 class="mb-75 mt-2 ml-5" style="font-weight: bold; font-size: 3em;">
                                {{cantidadArchivos?.cargados || 0}}
                            </h1>
                            <img src="assets/images/illustration/badge.svg" class="congratulation-medal"
                                alt="Medal Pic" />
                        </div>
                    </div>
                </div>

                <div class="col-md-8">
                    <div class="card card-statistics">
                        <div class="card-header">
                            <h4 class="card-title" style="font-weight: bold;">Procesos / Archivos por Estado</h4>
                            <!-- <div class="d-flex align-items-center">
                                <p class="card-text font-small-2 mr-25 mb-0">Updated 1 month ago</p>
                            </div> -->
                        </div>
                        <div class="card-body statistics-body">
                            <div class="row d-flex justify-content-between">
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="loader" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{cantidadArchivos?.procesados || 0}}</h4>
                                            <p class="card-text font-small-3 mb-0">En Proceso</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-info mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="check-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{cantidadArchivos?.aprobados || 0}}</h4>
                                            <p class="card-text font-small-3 mb-0">Aceptados</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3 mb-2 mb-sm-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <i data-feather="x-circle" class="avatar-icon"></i>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{cantidadArchivos?.rechazados || 0}}</h4>
                                            <p class="card-text font-small-3 mb-0">Rechazados</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row match-height ng-star-inserted">
                <div class="col-md-6">
                    <div class="card card-transaction">
                        <div class="card-body">
                            <h4 style="font-weight: bold;">Transacciones</h4>
                            <p class="text-secondary">Estadísticas</p>
                            <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    <h1 class="mt-1" style="font-weight: bold; font-size: 3em;">{{(transaccionesTotales?.cargadas | number) || 0}}</h1>
                                    <p class="text-secondary mb-2">Total Procesadas</p>
                                    <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded" (click)="changePercentage(1)">
                                                <div class="avatar-content">
                                                    <i data-feather="pocket" class="avatar-icon font-medium-3"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Aprobadas</h6>
                                                <small class="text-secondary">{{transaccionesTotales?.aprobadas || 0}} <small class="text-success" *ngIf="transaccionesAprobadas > 0"> ({{transaccionesAprobadas | number: '1.0-0'}}%)</small></small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-danger rounded"  (click)="changePercentage(2)">
                                                <div class="avatar-content">
                                                    <i data-feather="check" class="avatar-icon font-medium-3"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Rechazadas</h6>
                                                <small class="text-secondary">{{transaccionesTotales?.rechazadas || 0}} <small class="text-danger" *ngIf="transaccionesRechazadas > 0"> ({{transaccionesRechazadas | number: '1.0-0'}}%)</small></small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-primary rounded"  (click)="changePercentage(3)">
                                                <div class="avatar-content">
                                                    <i data-feather="loader" class="avatar-icon font-medium-3"></i>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">En Proceso</h6>
                                                <small class="text-secondary">{{transaccionesTotales?.procesados || 0}} <small class="text-primary" *ngIf="transaccionesProceso > 0"> ({{transaccionesProceso | number: '1.0-0'}}%)</small></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8 col-sm-12" #supportChartRef>
                                   
                                    <div id="supportChartoptions">
                                        <!-- Apexchart component-->
                                        <apx-chart
                                        [series]="data.supportTracker.series"
                                        [chart]="{
                                            height: 320,
                                            width: isMenuToggled === true ? supportChartRef.offsetWidth : supportChartoptions.chart.width,
                                            type: 'radialBar',
                                            sparkline: {
                                            enabled: false
                                            }
                                        }"
                                        [plotOptions]="supportChartoptions.plotOptions"
                                        [colors]="supportChartoptions.colors"
                                        [fill]="supportChartoptions.fill"
                                        [stroke]="supportChartoptions.stroke"
                                        [labels]="supportChartoptions.labels"
                                        ></apx-chart>
                                        <!-- / Apexchart component-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-transaction">
                        <div class="card-body">
                            <h4 style="font-weight: bold;">Transacciones</h4>
                            <p class="text-secondary">Montos</p>
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <!-- <h1 class="mt-1" style="font-size: 2.5em;">${{sumaMontosTotales | number}}</h1> -->
                                    <h1 class="mt-1" style="font-weight: bold; font-size: 3em;">${{(montosTotales?.cargados | number) || 0}}</h1>
                                    <p class="text-secondary mb-2">Montos Procesados</p>
                                    
                                </div>
                                <div class="col-md-12 col-sm-12 mt-1">
                                    <div class="card border-secondary-card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="transaction-item">
                                                        <div class="media d-flex align-items-center">
                                                            <div class="avatar bg-light-info rounded">
                                                                <div class="avatar-content">
                                                                    <i data-feather="check-circle" class="avatar-icon font-medium-3"></i>
                                                                </div>
                                                            </div>
                                                            <div class="media-body">
                                                                <h6 class="transaction-title">Montos Aprobados</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 class="my-1 font-weight-bold" style="font-size: 1.5em;">${{(montosTotales?.aprobados | number) || 0}}</h6>
                                                    <ngb-progressbar
                                                        type="info"
                                                        [height]="'6px'"
                                                        [value]="valueMontosAprobados"
                                                    ></ngb-progressbar>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="transaction-item">
                                                        <div class="media d-flex align-items-center">
                                                            <div class="avatar bg-light-danger rounded">
                                                                <div class="avatar-content">
                                                                    <i data-feather="x-circle" class="avatar-icon font-medium-3"></i>
                                                                </div>
                                                            </div>
                                                            <div class="media-body">
                                                                <h6 class="transaction-title">Montos Rechazados</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 class="my-1 font-weight-bold" style="font-size: 1.5em;">${{(montosTotales?.rechazados | number) || 0}}</h6>
                                                    <ngb-progressbar
                                                        type="danger"
                                                        [height]="'6px'"
                                                        [value]="valueMontosRechazados"
                                                    ></ngb-progressbar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>