import { HttpClient, HttpErrorResponse, HttpParams  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HistorialCargaService {
  public processEndPoint: string = `${environment.apiProcessService}/processes`;
  public generatorUrlEndPoint: string = `${environment.apiGenerateUrl}`;

  /**
     * Constructor
     *
     * @param {HttpClient} http
     */
  constructor(private http: HttpClient) {
  }
  /**
   * Get rows
   */
  getTrxDataTable(comercio_id: string, cantidadRegistros: number, lastHashKey, lastRangeKey, lastRangeIndexKey, fechaInicio, fechaFin) {
    if (fechaInicio) {
      let fechaInicial, fechaFinal;
      fechaInicial = fechaInicio.year + "-" + this.formatDate(fechaInicio.month) + "-" + this.formatDate(fechaInicio.day);
      if (fechaFin) {
        fechaFinal = fechaFin.year + "-" + this.formatDate(fechaFin.month) + "-" + this.formatDate(fechaFin.day);
      } else {
        fechaFinal = fechaInicial;
      }
      if (lastHashKey) {
        return this.http.get(`${this.processEndPoint}/byComercioId?comercioId=${comercio_id}&maxResultSize=${cantidadRegistros}&startKeyComercioId=${lastHashKey}&startKeyArchivoId=${lastRangeKey}&startKeyFechaProceso=${lastRangeIndexKey}&fromDate=${fechaInicial}&toDate=${fechaFinal}`);
      }
      return this.http.get(`${this.processEndPoint}/byComercioId?comercioId=${comercio_id}&maxResultSize=${cantidadRegistros}&fromDate=${fechaInicial}&toDate=${fechaFinal}`);
    } else {
      if (comercio_id) {
        if (lastHashKey) {
          return this.http.get(`${this.processEndPoint}/byComercioId?comercioId=${comercio_id}&maxResultSize=${cantidadRegistros}&startKeyComercioId=${lastHashKey}&startKeyArchivoId=${lastRangeKey}&startKeyFechaProceso=${lastRangeIndexKey}`);
        }
        return this.http.get(`${this.processEndPoint}/byComercioId?comercioId=${comercio_id}&maxResultSize=${cantidadRegistros}`);
      } else {

        //Generar Error acpá
        /* if (lastHashKey) {
          return this.http.get(`${this.processEndPoint}/all?maxResultSize=${cantidadRegistros}&startKeyComercioId=${lastHashKey}&startKeyArchivoId=${lastRangeKey}`);
        }
        return this.http.get(`${this.processEndPoint}/all?maxResultSize=${cantidadRegistros}`); */
      }
    }

  }

  /**
   * Obtiene la url para descargar el archivo
   */
  getUrlFile(idComercio: string, nombreArchivo: string, archivoOriginal: string ) {
    let params = new HttpParams();
    params = params.append('idComercio', idComercio);
    params = params.append('nombreArchivo', nombreArchivo);
    params = params.append('archivoOriginal', archivoOriginal);
    return this.http.get(`${this.generatorUrlEndPoint}/file`, { params: params }).pipe(
      tap(response => {
        if (response) {
        }
      }),
      catchError(this.handleError)
    )
  }

  getProcesoById(comercioId: number, idSolicitud: string) {
    let params = new HttpParams();
    params = params.append('comercioId', comercioId);
    params = params.append('archivoId', idSolicitud);
    return this.http.get(`${this.processEndPoint}/byComercioArchivoId`, { params: params });
  }

  formatDate(num: number): string {
    if (num < 10)
      return '0' + num;
    return num + '';
  }


    /**
  * Catch errors on call WS
  * @param error
  */
    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        console.error('Ocurrió un error:', error.error.message);
      }
      return throwError(error);
    }
  

}
