import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Proceso } from 'app/main/models/proceso';
import { SweetAlertService } from 'app/main/service/sweet-alert.service';
import { HistorialCargaService } from '../historial-carga.service';

@Component({
  selector: 'app-detalle-carga',
  templateUrl: './detalle-carga.component.html',
  styleUrls: ['./detalle-carga.component.scss']
})
export class DetalleCargaComponent implements OnInit {

  public contentHeader: object;
  public formRecaudo: FormGroup;
  public proceso: Proceso;
  public cargandoRecaudo = false;

  constructor(private historicoRecaudoService: HistorialCargaService, private formBuilder: FormBuilder,
    private router: Router, private activatedRoute: ActivatedRoute, private alert: SweetAlertService) { }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Detalle Procesos',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Procesos',
            isLink: true,
            link: '/historial-carga'
          },
          {
            name: 'Ver',
            isLink: false,
            link: '/'
          }
        ]
      }
    };

    this.formRecaudo = this.formBuilder.group({
      comercioId: [{value:'', disabled:true}],
      archivoId: [{value:'', disabled:true}],
      bancoId: [{value:'', disabled:true}],
      nombreBanco: [{value:'', disabled:true}],
      convenioId: [{value:'', disabled:true}],
      nombreConvenio: [{value:'', disabled:true}],
      descripcion: [{value:'', disabled:true}],
      mensajeRespuesta: [{value:'', disabled:true}],
      estado: [{value:'', disabled:true}],
      estadoView: [{value:'', disabled:true}],
      fechaProceso: [{value:'', disabled:true}],
      fechaHoraProceso: [{value:'', disabled:true}],
      secuencia: [{value:'', disabled:true}],
      totalTx: [{value:'', disabled:true}],
      totalValorTx: [{value:'', disabled:true}],
      totalTxAprobadas: [{value:'', disabled:true}],
      totalValorTxAprobadas: [{value:'', disabled:true}],
      totalTxRechazadas: [{value:'', disabled:true}],
      totalValorTxRechazadas: [{value:'', disabled:true}],
      tipoFormato: [{value: '', disabled:true}],
      archivoOriginal: [{value: '', disabled:true}]
    });


    this.cargandoRecaudo = true;
    this.activatedRoute.params.subscribe(params => {
      let comercioId = params['comercioId'];
      let idSolicitud = params['idSolicitud'];
      this.historicoRecaudoService.getProcesoById(comercioId, idSolicitud).subscribe(data => {
        if (data != null) {
          this.proceso = data['data'] as Proceso;
          this.cargarProceso();
          this.cargandoRecaudo = false;
        } else {
          this.cargandoRecaudo = false;
          this.alert.alertNotification('error', 'El archivo no existe!');
          this.linkProcesos();
        }
      }, err => {
        console.log(err);
        this.cargandoRecaudo = false;
        this.alert.alertNotification('error', 'Error al obtener el archivo!, intentelo nuevamente ');
        this.linkProcesos();
      })
    })
  }

  cargarProceso(){
    console.log("holaL: ")
    console.log(this.proceso)
    this.formRecaudo.setValue({
      comercioId: this.proceso.comercioId,
      archivoId: this.proceso.archivoId,
      bancoId: this.proceso.bancoId,
      nombreBanco: this.proceso.nombreBanco,
      convenioId: this.proceso.convenioId,
      nombreConvenio: this.proceso.nombreConvenio,
      descripcion: this.proceso.descripcion,
      mensajeRespuesta: this.proceso.mensajeRespuesta,
      estado: this.proceso.estado,
      estadoView: this.proceso.estadoView,
      fechaProceso: this.proceso.fechaProceso,
      fechaHoraProceso: this.proceso.fechaHoraProceso,
      secuencia: this.proceso.secuencia,
      totalTx: this.proceso.totalTx,
      totalValorTx: this.proceso.totalValorTx,
      totalTxAprobadas: this.proceso.totalTxAprobadas,
      totalValorTxAprobadas: this.proceso.totalValorTxAprobadas,
      totalTxRechazadas: this.proceso.totalTxRechazadas,
      totalValorTxRechazadas: this.proceso.totalValorTxRechazadas,
      tipoFormato: this.proceso.tipoFormato,
      archivoOriginal: this.proceso.archivoOriginal
    });
  }

  linkProcesos() {
    this.router.navigate(['/historial-carga']);
  }
}