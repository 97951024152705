<div class="content-area-wrapper container-xxl p-0">
  <div class="container">
      <div class="row">

        <div class="col-5">
          <div class="card">
          <div class="table-responsive ml-2 mr-2 mt-2 mb-2" style="max-height: 500px;overflow-y: auto;">
              <h3 class="font-weight-bolder ml-2">Notificaciones</h3>

              <table class="table table-borderless mb-0 mt-2" *ngFor="let row of rows"  (click)="show(row, 1)">
                <tbody [ngClass]= "{'my-opacity': row?.visto === 1}">
                <tr>
                  <div class="media d-flex align-items-start scrollable-container media-list">
                      <div class="media-left">
                     {{row?.fechaCargue|date: 'fullDate'}}
                      </div>
                  </div>
                </tr>
                <tr>
                <div class="media d-flex align-items-start scrollable-container media-list mt-1">
                  <div class="media-left">
                    <div
                      class="avatar"
                      [ngClass]="{
                        'bg-light-danger': row?.estado === 'Fallido',
                        'bg-light-success': row?.estado === 'Verificado',
                        'bg-light-warning': row?.estado === 'Anulado',
                        'bg-light-warning': row?.estado === 'En proceso'
                      }"
                    >
                    <div class="avatar-content" *ngIf="row?.estado=='En proceso'"><i class="avatar-icon" [data-feather]="'refresh-cw'"></i></div>
                    <div class="avatar-content" *ngIf="row?.estado=='Verificado'"><i class="avatar-icon" [data-feather]="'check'"></i></div>
                    <div class="avatar-content" *ngIf="row?.estado=='Anulado'"><i class="avatar-icon" [data-feather]="'alert-triangle'"></i></div>
                    <div class="avatar-content" *ngIf="row?.estado=='Fallido'"><i class="avatar-icon" [data-feather]="'x'"></i></div>
                    </div>
                  
                  </div>
                  <div class="media-body scrollable-container media-list">
                    <p class="media-heading">
                      <span class="font-weight-bolder" *ngIf="row?.estado=='En proceso'">{{message_proceso}}</span>
                      <span class="font-weight-bolder" *ngIf="row?.estado=='Verificado'">{{message_exitoso}}</span>
                      <span class="font-weight-bolder" *ngIf="row?.estado=='Anulado'">{{message_anulado}}</span>
                      <span class="font-weight-bolder" *ngIf="row?.estado=='Fallido'">{{message_rechazado}}</span>
                    </p>
                    <small class="notification-text">{{row?.descripcion}}</small>
                  </div>
                </div>
                </tr>
                  </tbody>
              </table>

              <!-- Notifications footer -->
              <div class="dropdown-menu-footer mt-2" >
                  <button type="button" class="btn btn-dark" rippleEffect (click)="readNextRows()"
                          *ngIf="!allRowsRead && onInitloaded">Cargar mas</button>
              </div>
              <!--/ Notifications footer -->

              </div>
          </div>
      </div>


        <div class="col-7">
          <div class="card">
            <div id="account-details-vertical-modern" class="content ml-3 mr-2 mt-2 mb-2" *ngIf="showRightPanel">
              <div class="content-header">
                <h3 class="mb-0"  *ngIf="currentRow?.estado=='En proceso'"><strong>{{message_proceso}}</strong></h3>
                <h3 class="mb-0"  *ngIf="currentRow?.estado=='Verificado'"><strong>{{message_exitoso}}</strong></h3>
                <h3 class="mb-0"  *ngIf="currentRow?.estado=='Anulado'"><strong>{{message_anulado}}</strong></h3>
                <h3 class="mb-0"  *ngIf="currentRow?.estado=='Fallido'"><strong>{{message_rechazado}}</strong></h3>
              </div>

              <div class="row">
                <div class="form-group col-md-6 mt-2">
                  <h5 class="form-label" for="vertical-modern-username"><strong>Nombre</strong></h5>
                </div>
              </div>  

              <div class="row">
                <div class="form-group col-md-6">
                  <h6 class="form-label" for="vertical-modern-username">{{currentRow.idSolicitud}}</h6>
                </div>
              </div>  
              

              <div class="row">
                <div class="form-group col-md-6 mt-2">
                  <h5 class="form-label" for="vertical-modern-username"><strong>Fecha de carga</strong></h5>
                </div>
                
                <div class="form-group col-md-6 mt-2">
                  <h5 class="form-label" for="vertical-modern-username"><strong>Cantidad de registros</strong></h5>
                </div>
                
              </div>  

              <div class="row">
                <div class="form-group col-md-6">
                  <h6 class="form-label" for="vertical-modern-username">{{currentRow.fechaCargue}}</h6>
                </div>
                
                <div class="form-group col-md-6">
                  <h6 class="form-label" for="vertical-modern-username">{{currentRow.numeroPagos}}</h6>
                </div>
              </div>
      
              <div class="row">
                <div class="form-group col-md-6 mt-2">
                  <h5 class="form-label" for="vertical-modern-username"><strong>Descripcion</strong></h5>
                </div>
              </div>  

              <div class="row">
                <div class="form-group col-md-6">
                  <h6 class="form-label" for="vertical-modern-username">{{currentRow.descripcion}}</h6>
                </div>
              </div>  

              <div class="row">
                <div class="form-group col-md-6 mt-2">
                  <h5 class="form-label" for="vertical-modern-username"><strong>Transacciones aprobadas</strong></h5>
                </div>
                
                <div class="form-group col-md-6 mt-2">
                  <h5 class="form-label" for="vertical-modern-username"><strong>Transacciones declinadas</strong></h5>
                </div>
                
              </div>  

              <div class="row">
                <div class="form-group col-md-6">
                  <h6 class="form-label" for="vertical-modern-username">0</h6>
                </div>
                
                <div class="form-group col-md-6">
                  <h6 class="form-label" for="vertical-modern-username">0</h6>
                </div>
              </div>


              <div class="dropdown-menu-footer mt-2" >
                <button type="button" class="btn btn-dark"  
                      [routerLink]="['/historial-transacciones',currentRow.archivoId]" rippleEffect>
                      Ver Transacciones</button>
              </div>

          </div>
        </div>
      </div>
  </div>
</div>