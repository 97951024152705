import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, of, BehaviorSubject, Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { map, tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from 'app/main/service/local-storage.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  public currentUser: Observable<User>;
  public loggedIn: BehaviorSubject<boolean>;
  private currentUserSubject: BehaviorSubject<User>;

  constructor( private router: Router, private localStorageService:LocalStorageService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  public isAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          this.loggedIn.next(true);
          return true;
        }),
        catchError(error => {
          this.loggedIn.next(false);
          return of(false);
        })
      );
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get isAdmin() {
    return false;
    //return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  get isClient() {
    return false;
    //return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  forgotPassword(username: string) {
    return Auth.forgotPassword(username);
  }

  forgotPasswordSubmit(username: string, codigo: string, password: string) {
    return Auth.forgotPasswordSubmit(username, codigo, password);
  }

 /*  login(username: string, password: string) {
    return Auth.signIn(username, password);
  } */

  login(user: User) {

    this.currentUserSubject.next(user);
    return user;  
  }



  savePayload(atributos) {
    localStorage.setItem('payload', JSON.stringify(atributos));
  }

  getPayload() {
    return JSON.parse(localStorage.getItem('payload'));
  }

  completeNewPassword(user, password: string) {
    return Auth.completeNewPassword(user, password);
  }

  confirmSignIn(user, code:string) {
    return Auth.confirmSignIn(user, code,"SOFTWARE_TOKEN_MFA");
  }

  public logout() {
    from(Auth.signOut())
      .subscribe(
        result => {
          this.loggedIn.next(false);
          this.localStorageService.removeItem('payload');
          this.localStorageService.removeItem('comercioSelected')
          this.router.navigate(['/pages/authentication/login-v1']);
        },
        error => console.log(error)
      );
  }
}
