import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { NotificationsService } from 'app/main/dashboard/lista-notificaciones/notifications.service';
import { of, Subscription, timer } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications;
  public notif:any[] = [];
  public faltaLeer: number = 0;
  public leido = [];
  public noleido = [];

  public comercio_id: string = '';
  public message_exitoso: string = 'Archivos Enviados con exito';
  public message_en_proceso: string = 'La carga de los archivos estan en proceso...';
  public message_procesado: string = 'El archivo se proceso correctamente';
  public message_rechazado: string = 'Error al enviar los archivos';  
  subscription: Subscription;

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private authService: AuthenticationService, private _notificationsService: NotificationsService) { }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    let attributes = this.authService.getPayload();
    this.comercio_id = '';
    if (attributes && attributes['custom:comercio_id']) {
      this.comercio_id = attributes['custom:comercio_id'];
    } else {
      return;
    }

    const subs$ = timer(0, 180000);
    subs$.subscribe((d) => {
      return this._notificationsService.readNextProcesos(this.comercio_id, 10, null, null, null)
      .subscribe(res => {
          this.faltaLeer = 0;
          this.notif = [];
          res['processes'].forEach(element => {
            if(element['visto'] === 0){
              this.faltaLeer++;
            }
          });
          //Solo se muestran las dos primeras notificaciones
          const cantidadMostrar = 2;
          for(let i = 0; i < cantidadMostrar; i++){
            this.notif.push(res['processes'][i]);
          }
        },err => {
          console.log(err);
          console.log('Error en la ejecucion del servicio numero: ' + d);
        })
    })

    /*this.subscription = timer(1000)
    .pipe(
      
      switchMap(() => {
        console.log("se ejecuto");
        return this._notificationsService.readNextProcesos(this.comercio_id, 10, null, null, null)
          .pipe(catchError(err => {
            // Handle errors
            console.error(err);
            return of(undefined);
          }));
      }),
      filter(data => data !== undefined)
    )
    .subscribe(res => {
      console.log(res);
      console.log("1");
      this.faltaLeer = 0;
      
      this.notif = res['processes'];
      this.notif.forEach(element => {
        let estado = element['estado'];
        if (estado) {
            estado = estado.toLowerCase();
        }

        if (estado === 'en proceso') {
          console.log("Entro: En proceso");
            if (!element['visto'] || element['visto'] !== 0) 
                this.faltaLeer++;
        }else {
          console.log("No Entro: En proceso");
            if (!element['visto'] || element['visto'] !== 1) 
                console.log(element['visto']);
                this.faltaLeer++;
                console.log("Entro falta leer: " + this.faltaLeer);
        }
      });
    });*/
  }



}
