<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span *ngIf="faltaLeer > 0" class="badge badge-pill badge-danger badge-up">{{ faltaLeer }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
    style="max-height: 600px;overflow-y: auto;"
  >

    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notificaciones</h4>
        <div *ngIf="faltaLeer > 0" class="badge badge-pill badge-light-primary">
          {{ faltaLeer }} Nuevos
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" href="javascript:void(0)" *ngFor="let row of notif">
        <div class="media d-flex align-items-start my-opacity"   [ngClass]="{'my-opacity': (row?.visto && row?.visto === 1)}">
          <div class="media-left">
            <div
              class="avatar"
              [ngClass]="{
                'bg-light-secondary': row?.estado === 'Verificado',
                'bg-light-warning': row?.estado === 'En proceso',
                'bg-light-success': row?.estado === 'Procesado',
                'bg-light-danger': row?.estado === 'Fallido'
              }"
            >
             
            <div class="avatar-content" *ngIf="row?.estado=='Verificado'"><i class="avatar-icon" [data-feather]="'check'"></i></div>
            <div class="avatar-content" *ngIf="row?.estado=='En proceso'"><i class="avatar-icon" [data-feather]="'refresh-cw'"></i></div>
            <div class="avatar-content" *ngIf="row?.estado=='Procesado'"><i class="avatar-icon" [data-feather]="'check-circle'"></i></div>
            <div class="avatar-content" *ngIf="row?.estado=='Fallido'"><i class="avatar-icon" [data-feather]="'x'"></i></div>
            </div>

          </div>
          <div class="media-body">
            <p class="media-heading">
              <span class="font-weight-bolder" *ngIf="row?.estado=='Verificado'">{{message_exitoso}}</span>
              <span class="font-weight-bolder" *ngIf="row?.estado=='En proceso'">{{message_en_proceso}}</span>
              <span class="font-weight-bolder" *ngIf="row?.estado=='Procesado'">{{message_procesado}}</span>
              <span class="font-weight-bolder" *ngIf="row?.estado=='Fallido'">{{message_rechazado}}</span>
            </p>
            <small class="notification-text">{{row?.descripcion}}</small>
            <p class="notification-text">Fecha cargue: {{row?.fechaCargue}}</p>
          </div>
        </div>
    </li>

<!---
    <li class="media-list" *ngIf="leido.length > 0">
      <h6 class="font-weight-bolder ml-2">Vistos</h6>
    </li>
    <li class="media-list d-flex align-items-center" style="opacity: .4;" href="javascript:void(0)" *ngFor="let row of leido">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
              class="avatar"
              [ngClass]="{
                'bg-light-danger': row?.estado === 'Rechazado',
                'bg-light-success': row?.estado === 'Exitoso',
                'bg-light-warning': row?.estado === 'En proceso'
              }"
            >
             
            <div class="avatar-content" *ngIf="row?.estado=='En proceso'"><i class="avatar-icon" [data-feather]="'refresh-cw'"></i></div>
            <div class="avatar-content" *ngIf="row?.estado=='Exitoso'"><i class="avatar-icon" [data-feather]="'check'"></i></div>
            <div class="avatar-content" *ngIf="row?.estado=='Rechazado'"><i class="avatar-icon" [data-feather]="'x'"></i></div>
            </div>

          </div>
          <div class="media-body">
            <p class="media-heading">
              <span class="font-weight-bolder" *ngIf="row?.estado=='En proceso'">{{message_proceso}}</span>
              <span class="font-weight-bolder" *ngIf="row?.estado=='Exitoso'">{{message_exitoso}}</span>
              <span class="font-weight-bolder" *ngIf="row?.estado=='Rechazado'">{{message_rechazado}}</span>
            </p>
            <small class="notification-text">{{row.descripcion}}</small>
          </div>
        </div>
    </li>
-->
  </ul>
</li>


