import { Component, OnInit, ViewChild } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { CargaArchivoService } from './carga-archivos.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { convenio } from 'app/main/models/convenio';
import { formato } from 'app/main/models/formato';
import { Router } from '@angular/router';
import { GeneralResponse } from 'app/main/models/GeneralResponse';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { PatternsValidators } from 'app/util/patterns';
import { formatobyid } from 'app/main/models/formatobyid';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'app/main/service/local-storage.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-carga-archivos',
  templateUrl: './carga-archivos.component.html',
  styleUrls: ['./carga-archivos.component.scss']
})
export class CargaArchivosComponent implements OnInit {

  constructor(private cargaService: CargaArchivoService, private modalService: NgbModal, private router: Router,private alert: SweetAlertService, private authService: AuthenticationService, private localStorageService:LocalStorageService) { }
  public generalReponse:GeneralResponse;
  public contentHeader: object
  public mensajeError: string = '';
  public patternFile: string = PatternsValidators.PATTERN_NOMBRE_ARCHIVO_TEMPLATE;
  public fileNameText: string = '';
  public isFileInputTouched: boolean = false;
  public archivoCargado: boolean = false;
  public uploadUrl: string = '';
  // formulario
  public descripcion: string = '';
  public convenioSelected: number = -1;
  public formatoSelected: number = -1;
  public formato: formato[];
  public convenio: convenio[];
  public comercio_id: number;
  public buttonEnabled = false;
  public idSolicitud: string;
  public submitArchive = false;
  public formatobyId: formatobyid[];
  public convenioSelectedName: string;

  private storageSub: Subscription;
  // modal
  @ViewChild("modalSuccess") modalSuccess;

  public uploader: FileUploader = new FileUploader({
    isHTML5: true,
    autoUpload: false,
    disableMultipart: true,
    headers: [ // XHR request headers
      {
        name: 'X-Requested-With',
        value: 'XMLHttpRequest'
      }
    ]
  });


  ngOnInit(): void {

    this.comercio_id=this.localStorageService.getItem('comercioSelected')

    this.initContentHeader();

    this.uploader.onAfterAddingFile = (fileItem: FileItem) => this.onAfterAddingFile(fileItem)

    this.readConvenio();

    this.storageSub = this.localStorageService.watchStorage().subscribe((key: string) => {
      if (key === 'comercioSelected') {
        this.readConvenio();
      }
    });
  }

  initContentHeader(){
    this.contentHeader = {
      headerTitle: 'Carga de archivos',
      actionButton: false,
      breadcrumb: {}
    };
  }

  ngOnDestroy(): void {
    if (this.storageSub) {
      this.storageSub.unsubscribe();
    }
  }

  fileInputSelected(e: any) {
    if (e.type === 'click' || e.code === 'Enter' || e.code === 'NumpadEnter' || e.code === 'Space' || e.code === 'Spacebar') {
      this.isFileInputTouched = true;
    }
  }

  onAfterAddingFile(fileItem: FileItem) {
    let latestFile = this.uploader.queue[this.uploader.queue.length - 1]
    this.fileNameText = latestFile.file.name;
    this.uploader.queue = [];
    this.uploader.queue.push(latestFile);
  }

  uploadFile() {
    this.mensajeError = '';
    this.submitArchive = true;
    this.buttonEnabled = true;
    let filename = this.uploader.queue[0].file.name;
    let convenio = this.convenio.find((x) => x.id == this.convenioSelected).id;

    this.cargaService.getPresignedUrl(filename, this.comercio_id, convenio.toString(), this.descripcion, this.formatoSelected).subscribe(resp => {
      this.generalReponse = resp as GeneralResponse;
      if (this.generalReponse.status != 200 || !this.generalReponse.data.key) {
        this.submitArchive = false;
        this.mensajeError = 'Hubo un error al cargar el archivo, por favor verifique los datos enviados';
      } else {
        this.mensajeError = '';
        this.archivoCargado = true;
        this.uploadUrl = this.generalReponse.data.url;
        let newProcess = this.generalReponse.data.key.includes('_') ? this.generalReponse.data.key.split('_')[1].split('.')[0] : this.generalReponse.data.key.split('.')[0];
        this.idSolicitud = newProcess;
        if (localStorage.getItem('newProcess')) {
          localStorage.setItem('newProcess', newProcess);
        }
        localStorage.setItem('newProcess', newProcess);

        this.uploader.queue.forEach((item) => {
          item.url = this.uploadUrl;
          item.method = 'PUT';
          item.headers = [{ name: 'Content-Type', value: item.file.type }];
          item.withCredentials = false;
          item.upload();
        })
        this.submitArchive = false;
        this.uploader.onErrorItem = (item, response, status, headers) => {
          this.mensajeError = 'Hubo un error al subir el archivo, por favor verifique los datos enviados';
        }
        this.uploader.onSuccessItem = (item, response, status, headers) => {
          this.modalOpenSuccess();
          this.findNameConvenio();
        }
      }
    }, err => {
      if (err.status == 400 && err.error.message)
        this.mensajeError = err.error.message;
      else this.mensajeError = 'Hubo un error al cargar el archivo, por favor verifique los datos enviados';
      this.submitArchive = false;
    });
  }

  // modal Open Success
  modalOpenSuccess() {
    this.modalService.open(this.modalSuccess, {
      centered: true,
      windowClass: 'modal modal-success',
      size: 'lg'
    });
  }

  readConvenio() {
    this.cargaService.getConveniosPorComercio(this.comercio_id).subscribe(data => {
      this.generalReponse = data as GeneralResponse;
      this.convenio = this.generalReponse.data;
      this.convenio.unshift({
        id: -1,
        nombre: '[Seleccione convenio]'
      })
    }, err => {
      this.alert.alertNotification('error', 'Error al obtener los convenios.')
    })
  }
  /*changeFormato(){
    if(this.convenioSelected == -1) {
      this.formato = [{id: -1, nombre:'[Seleccione un formato]'}];
    }else{

      this.readFormato(this.convenioSelected);
    }
    this.formatoSelected = -1;
  }*/

 /* readFormato(convenio_id) {
    this.cargaService.getFormatobyId(convenio_id).subscribe(data => {
      this.formatobyId = data;
      this.readFormat();
    })
  }*/

  /*readFormat(){
    this.cargaService.getFormato().subscribe(data => {
      this.formato = [{ id: -1, nombre: '[Seleccione formato]' }];
      data.forEach(element => {
        this.formatobyId.forEach(e=>{
          if(element.id == e.formatoId){
            this.formato.push(element);
          }
        })
      });
    })
  }*/

  findNameConvenio(){
    this.convenioSelectedName = this.convenio.find((x) => x.id == this.convenioSelected).nombre;
  }

  
  initialize() {
    this.uploader.clearQueue();

    this.mensajeError = '';
    this.archivoCargado = false;
    this.uploadUrl = '';
    this.descripcion = '';
    this.convenioSelected = -1;
    //this.formatoSelected = -1;

    this.readConvenio();

    this.modalService.dismissAll();
  }

}
