export class PatternsValidators {

    /**
     * Filename Rules:
     *      a) nombre de archivo debe empezar por caracter alfanumerico
     *      b) solo se permiten extensiones .txt y .csv
     *      c) solo se permiten caracteres especiales: punto(.), raya al piso(_), guion(-), espacio en blanco( )
     */
    public static get PATTERN_NOMBRE_ARCHIVO(): any {
        return /^(?![_ -])[a-zA-Z0-9_ -]+[a-zA-Z0-9._ -]?\.(txt|csv|TXT|CSV){1}$/;
    }

    public static get PATTERN_NOMBRE_ARCHIVO_TEMPLATE(): any {
        return '^(?![_ -])[a-zA-Z0-9_ -]+[a-zA-Z0-9._ -]?\.(txt|csv|Txt|TXt|txT|tXT|Scv|SCv|sCV|sCv|scV|tXt|TXT|CSV){1}$';
    }

    public static get PATTERN_EMAIL(): any {
        /*
        reglas: 
            a) solo se permiten caracteres alfanuméricos mas caracteres especiales . _ -
            b) nombre de buzón debe empezar por carácter alfanumérico (numero o letra)
            c) el nombre de buzón no debe terminar en punto (.)
            d) no se permite mas de 1 vez seguida, el carácter punto (.) 
        */
        return /^(?![_-])[a-zA-Z0-9_-]+(?:\.{1}[a-zA-Z0-9_-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])$/;
    }
}