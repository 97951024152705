import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service';
import { SweetAlertService } from 'app/main/services/sweet-alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private alert: SweetAlertService,
    private modalService: NgbModal
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("Interceptado")

    console.log("Rutas: " , request.url)

    const isAuthRoute = request.url.includes('/autenticacion/auth') 
    || request.url.includes('/pages/authentication/login-v1');    
    
    if(isAuthRoute){
      console.log("Entro")
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log("Error interceptado por ErrorInterceptor:", error); // Depuración

        if (error.status === 401 || error.status === 0) {
          console.log("Manejando sesión caducada o error de conexión"); // Depuración

          // Cerrar cualquier modal o alerta existente
          this.modalService.dismissAll();
          this.alert.closeAllAlerts();
          
          console.log("Alertas y modales cerrados, mostrando la alerta de sesión caducada"); // Depuración

          // Mostrar la alerta de sesión caducada
          this.alert.alertSessionExpired();

          // Redirigir al login o realizar cualquier otra acción después
          this._authenticationService.logout();
          this._router.navigate(['/pages/authentication/login-v1']);

          // Interrumpir cualquier flujo posterior
          return EMPTY; // Esto detiene el flujo sin propagar el error al componente
        }

        // Continuar con otros errores que no sean críticos
        return throwError(error);
      })
    );
  }
}
